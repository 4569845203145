*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
.tileMargin{
    margin:.5px !important;
    padding: 0px 0px 0px 0px !important;
}

